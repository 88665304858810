<template>
    <div v-if="form">
        <v-toolbar dense flat class="primary darken-4" dark>
            <div class="flex-grow-1"></div>
            <v-btn text class="white" color="black" :to="{ name: 'Encuestas' }">
                <v-icon>mdi-chevron-left</v-icon>
                regresar
            </v-btn>
        </v-toolbar>
        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
                Encuesta
                <v-icon>mdi-ballot-outline</v-icon>
            </v-tab>
        </v-tabs>
        <v-card class="pa-2" outlined tile>
            <v-container>
                <v-row class="caption" no-gutters v-if="form.tblCitaFacturaciones">
                    <v-col cols="12" sm="12" md="4">
                        <label class="primary--text">PERSONA: </label>

                        <label class="font-weight-black">{{
                form.copeNombre 
              }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                        <label class="primary--text">ENCUESTA: </label>

                        <label class="font-weight-black" v-if="form.encuesta.length > 0">{{ form.encuesta.coenNombre }}</label>
                    </v-col>
                </v-row>
            </v-container>
            <v-speed-dial v-model="fab" absolute direction="bottom" :right="true" transition="slide-y-reverse-transition" v-if="form.encuId">
                <template v-slot:activator>
                    <v-btn v-model="fab" color="primary darken-2" dark fab>
                        <v-icon v-if="fab">mdi-close</v-icon>
                        <v-icon v-else>mdi-menu-open</v-icon>
                    </v-btn>
                </template>
                <v-tooltip left >
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="green" v-on="on" :to="{
                                name: 'Createencuesta',
                                params: {
                                  id: form.encuId,
                                  encuesta: form.coenId,
                                },
                              }">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar encuesta</span>
                </v-tooltip>
                <v-tooltip left >
                    <template v-slot:activator="{ on }">
                        <v-btn fab dark small color="red" v-on="on" @click="eliminar(form.encuId)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Eliminar encuesta</span>
                </v-tooltip>
            </v-speed-dial>
        </v-card>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
                <historia :datos="{cecuFecha:form.cecuFecha,secciones:secciones }" v-if="form.encuId && secciones.length > 0" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import Historia from "./Historia";
import generales from '../../mixins/generales.js';
export default {
  components: {
    Historia
  },
  mixins: [generales],
  data() {
    return {
      tab: "tab-1",
      url: "encu/encuestas/",
      correo: "",
      historiaData: null,
      fab: false,
      dialog: false,
      Loading: false,
      secciones: [],
      campos: [],
      Historia: [],
      form: null
    }
  },

  created: async function() {
    this.initialize();
  },

  methods: {
    async initialize() {
        if (this.$route.params.id) {
          this.editar(this.$route.params.id);
        }
    },
    async editar(id) {
      try {
        if (this.getPermiso("verEncuestas")) {
          this.$store.commit('setCargaDatos', true);
          let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
          if (data) {
            this.form = data;
            let resultados = await this.$apiService.index("sistema/encuestarespuestas/resultados/" + this.form.encuId).then(data => data);
            if (!resultados.mensaje) {
              this.Historia = resultados.Historia;
              this.secciones = resultados.secciones;
            }
          }
          this.$store.commit('setCargaDatos', false);
          this.isNew = false;
        }

      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async eliminar(id) {
      try {
        if (this.getPermiso("eliminarEncuestas")) {
          var result = this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
          if (result) {
            let data = await this.$apiService.delete("sistema/encuestarespuestas/delete/" + id + "/").then(data => data);
            if (data) {
              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );

            }
          }
        }

      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    limpiar() {
      this.form = null;
    },

  }
}

</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
.v-speed-dial {
  position: absolute !important;
}

</style>
