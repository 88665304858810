<template>
    <div>
        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
                Encuesta
                <v-icon>mdi-calendar</v-icon>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-card class="md-12">
                                <v-col cols="12" sm="12" md="12" v-if="paciente">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="12" class="text-center">
                                            <span class="headline primary--text"> {{factura.encuesta.coenNombre}}</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <span class="headline primary--text">DATOS DE LA PERSONA</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <label class="primary--text">FECHA DE LA ENCUESTA: </label>
                                            {{factura.encuFecha}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">NOMBRE: </label>
                                            {{paciente.copeNombre }}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">IDENTIFICACION: </label>
                                            {{(((paciente.tipoidentificacion)?paciente.tipoidentificacion.comaNombrecorto:'') +' - '+ paciente.copeIdentificacion)}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">DIRECCION: </label>
                                            {{paciente.copeDireccion}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">TELEFONO: </label>
                                            {{paciente.copeTelefono}}
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-form @submit.prevent="agregar">
                                <v-stepper v-model="e1">
                                    <v-stepper-header>
                                        <v-stepper-step :editable="!isNew" :complete="e1 > 1" step="1">Encuesta</v-stepper-step>
                                    </v-stepper-header>
                                    <v-stepper-items class="grey lighten-3">
                                        <v-stepper-content step="1">
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-pagination circle v-model="pag" @input="cargar" @next="cargar" @previous="cargar" :total-visible="10" :length="seccionesPaginado.length"></v-pagination>
                                                <div class="flex-grow-1"></div>
                                                <v-btn color="red darken-1" text v-if="e1==1" @click="cancelar">Cancelar</v-btn>
                                            </v-card-actions>
                                            <v-divider></v-divider>
                                            <v-container class="mb-12 grey lighten-3" v-if="seccionesvista.length > 0 && !loading2">
                                                <transition-group name="list">
                                                    <v-row v-for="seccion in  seccionesvista" :key="'row-'+seccion.ecseCodigo" :id="'CONTENEDOR_'+seccion.ecseCodigo" class="white mt-5">
                                                        <v-col cols="12" sm="12" md="12" class="primary--text">
                                                            <v-tooltip v-if="seccion.ecseDescripcion" max-width="300" color="light-blue darken-4" bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <h3 v-on="on">{{seccion.ecseNombre}}</h3>
                                                                </template>
                                                                <span>{{seccion.ecseDescripcion}}</span>
                                                            </v-tooltip>
                                                            <h3 v-else>{{seccion.ecseNombre}}
                                                                <template v-if="seccion.ecseHistorico">
                                                                    <v-btn color="success" @click="ecseIdHistorico=seccion.ecseCodigo;ecseNombreHistorico=seccion.ecseNombre;dialogsHistorico=true" text class="ml-3">Historico</v-btn>
                                                                </template>
                                                            </h3>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" :md="c.ecseCol" v-for="c in  seccion.campos" :key="'col-'+c.ecseCodigo" :id="'CAMPO_'+c.ecseCodigo">
                                                            <div v-for="(item,index) in  formHistoria.filter( e=>e.ecseId == c.ecseId)" :key="'cam-'+item.ecseCodigo">
                                                                <input type="hidden" :id="'VALOR_'+c.ecseCodigo" :value="item.enerValor" />
                                                                <div v-if="c.ecseRequerido">
                                                                    <v-tooltip v-if="c.ecseDescripcion" max-width="300" color="light-blue darken-4" right>
                                                                        <template v-slot:activator="{ on }">
                                                                            <label v-on="on" :for="c.ecseCodigo">{{c.ecseNombre}}</label>
                                                                        </template>
                                                                        <span>{{c.ecseDescripcion}}</span>
                                                                    </v-tooltip>
                                                                    <label v-else :for="c.ecseCodigo">{{c.ecseNombre}}</label>

                                                                    <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                                        <v-text-field :id="c.ecseCodigo" dense outlined v-model="item.enerValor" :error="$v.formHistoria.$each[index].enerValor.$error"></v-text-field>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                                        <v-textarea :id="c.ecseCodigo" :rows="c.ecseFilas" dense outlined v-model="item.enerValor" :error="$v.formHistoria.$each[index].enerValor.$error"></v-textarea>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                                        <v-autocomplete :id="c.ecseCodigo" dense outlined v-model="item.enerValor" :error="$v.formHistoria.$each[index].enerValor.$error" :items="getValues(c.ecseValores)" item-text="texts" item-value="values" item-color="primary" clearable @change="ejecutarInput('VALOR_'+c.ecseCodigo,item.enerValor)"></v-autocomplete>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                                        <v-switch :id="c.ecseCodigo" dense outlined v-model="item.enerValor" :error="$v.formHistoria.$each[index].enerValor.$error" :true-value="c.ecseValores.split(';')[0]" :false-value="c.ecseValores.split(';')[1]" @change="ejecutarInput('VALOR_'+c.ecseCodigo,item.enerValor)"></v-switch>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                                                        <v-menu v-model="item.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-text-field required outlined :error="$v.formHistoria.$each[index].enerValor.$error" v-model="item.enerValor" append-icon="event" readonly v-on="on" clearable></v-text-field>
                                                                            </template>
                                                                            <v-date-picker locale="ES" v-model="item.enerValor" @input="item.menu = false"></v-date-picker>
                                                                        </v-menu>
                                                                    </template>

                                                                </div>
                                                                <div v-else>
                                                                    <v-tooltip v-if="c.ecseDescripcion" max-width="300" color="light-blue darken-4" right>
                                                                        <template v-slot:activator="{ on }">
                                                                            <label v-on="on" :for="c.ecseCodigo">{{c.ecseNombre}}</label>
                                                                        </template>
                                                                        <span>{{c.ecseDescripcion}}</span>
                                                                    </v-tooltip>
                                                                    <label v-else :for="c.ecseCodigo">{{c.ecseNombre}}</label>
                                                                    <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                                        <v-text-field :id="c.ecseCodigo" dense outlined v-model="item.enerValor"></v-text-field>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                                        <v-textarea :id="c.ecseCodigo" :rows="c.ecseFilas" dense outlined v-model="item.enerValor"></v-textarea>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                                        <v-autocomplete :id="c.ecseCodigo" dense outlined v-model="item.enerValor" :items="getValues(c.ecseValores)" item-text="texts" item-value="values" item-color="primary" @change="ejecutarInput('VALOR_'+c.ecseCodigo,item.enerValor)" clearable></v-autocomplete>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                                        <v-switch :id="c.ecseCodigo" dense outlined v-model="item.enerValor" :true-value="c.ecseValores.split(';')[0]" :false-value="c.ecseValores.split(';')[1]" @change="ejecutarInput('VALOR_'+c.ecseCodigo,item.enerValor)"></v-switch>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                                                        <v-menu v-model="item.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-text-field dense outlined v-model="item.enerValor" append-icon="event" readonly v-on="on" clearable></v-text-field>
                                                                            </template>
                                                                            <v-date-picker locale="ES" v-model="item.enerValor" @input="item.menu = false"></v-date-picker>
                                                                        </v-menu>
                                                                    </template>

                                                                </div>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </transition-group>
                                            </v-container>
                                            <v-container class="mb-12 grey lighten-3" v-if="loading2">
                                                <v-card :color="($vuetify.theme.dark)?'grey darken-4 mx-auto':'grey lighten-3 mx-auto'" width="180px" outlined dark>
                                                    <v-card-text class="text-center">
                                                        <span class="primary--text">Cargando datos </span>
                                                        <v-progress-circular indeterminate color="primary" class="mx-auto"></v-progress-circular>
                                                    </v-card-text>
                                                </v-card>
                                            </v-container>
                                            <v-card-actions>
                                                    <div class="flex-grow-1"></div>
                                                    <v-btn color="primary darken-1" dark @click="agregarEncuesta()">Finalizar</v-btn>
                                            </v-card-actions>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
        <div id="scripthistoria"></div>
    </div>
</template>

<script>
import postscribe from 'postscribe';
import {
  required
}
from 'vuelidate/lib/validators';
import generales from '../../mixins/generales.js';
export default {
  mixins: [generales],
  data() {
    return {
      e1: 1,
      pag: 1,
      tab: 'tab-1',
      dialog: false,
      ecseIdHistorico: null,
      ecseNombreHistorico: '',
      dialogsHistorico: false,
      insertando: false,
      menu: false,
      nuevo: false,
      factura: null,
      paciente: null,
      loading: false,
      loading2: false,
      seccionesvista: [],
      seccionesPaginado: [],
      url: "sistema/encuestarespuestas/",
      secciones: [],
      campos: [],
      formHistoria: [],
      formHistoriaValidado: [],
      script: '',
      pagAnterior: 0
    };
  },

  validations: {
    formHistoriaValidado: {
      $each: {
        enerValor: {
          required,
        },
      }
    },
    formHistoria: {
      $each: {
        enerValor: {
          required,
        },
      }
    },
    formHistoria2: {
      $each: {
        enerValor: {
          required,
        },
      }
    },
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    if (this.$route.params.id) {
      try {
        if (this.getPermiso("crearEncuestas")) {
          this.factura = await this.$apiService.index("encu/encuestas/view/" + this.$route.params.id).then(data => data);
          this.paciente = this.factura.persona;
          this.secciones = await this.$apiService.index("sistema/encuestassecciones/secciones/" + this.factura.coenId).then(data => data);
          this.formHistoria = await this.$apiService.index(this.url + "index/" + this.$route.params.id).then(data => data);
          if (this.formHistoria.length > 0) {
            this.isNew = false;
          } else {
            this.isNew = true;
          }
          let secciones = [];
          this.secciones = this.secciones.filter(e => e.ecseEstado);
          this.secciones = this.secciones.filter(e => (e.ecseSexo == null || e.ecseSexo == 0 || e.ecseSexo == ((this.paciente.sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
            (e.ecseEdadinicio == null || ((new Date().getTime() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.ecseEdadinicio) &&
            (e.ecseEdadfinal == null || ((new Date().getTime() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.ecseEdadfinal));
          this.secciones.forEach((seccion, index) => {
            if (seccion.campos.length > 0 && seccion.ecseEstado) {
              seccion.campos.sort((a, b) => a.ecseOrden - b.ecseOrden);

              seccion.campos = seccion.campos.filter(e => e.ecseEstado);
              seccion.campos = seccion.campos.filter(e => (e.ecseSexo == null || e.ecseSexo == 0 || e.ecseSexo == ((this.paciente.sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
                (e.ecseEdadinicio == null || ((new Date().getTime() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.ecseEdadinicio) &&
                (e.ecseEdadfinal == null || ((new Date().getTime() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.ecseEdadfinal));
              seccion.campos.forEach(campo => {
                if (campo.ecseEstado) {
                  if (this.formHistoria.find(e => e.ecseId == campo.ecseId) == undefined) {
                    this.formHistoria.push({
                      enerId: null,
                      menu: false,
                      enerValor: (campo.ecseDefault) ? campo.ecseDefault : (campo.ecseRequerido) ? null : ((campo.tipodato.comaNombrecorto == 'SI/NO') ? 'NO' : (campo.tipodato.comaNombrecorto == 'TEXTAREA') ? 'NORMAL' : null),
                      encuId: this.$route.params.id,
                      ecseId: campo.ecseId
                    });
                  }
                  if (campo.ecseRequerido) {
                    this.formHistoriaValidado.push({
                      enerValor: null,
                      ecseId: campo.ecseId
                    });
                  }
                }

              });
            }
            if ((index % 4) == 0 && index != 0) {
              secciones.push(seccion);
              this.seccionesPaginado.push({
                pag: index / 4,
                secciones: secciones
              });
              secciones = [];
            } else {
              if ((index + 1) == this.secciones.length) {
                secciones.push(seccion);
                this.seccionesPaginado.push({
                  pag: (this.seccionesPaginado.length + 1),
                  secciones: secciones
                });
                secciones = [];
              } else {
                secciones.push(seccion);
              }

            }

          });
          if (this.seccionesPaginado.find(e => e.pag == 1)) {
            this.seccionesvista = this.seccionesPaginado.find(e => e.pag == 1).secciones;

          }
          if (this.isNew) {
            this.nuevo = true;
          }
          let tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_ENCUESTA").then(data => data);
          let encuTipoEstado = tipoestado.find(e => e.comaNombrecorto == 'EN_PROCESO').comaId;
          await this.$apiService.update( "encu/encuestas/update/" + this.$route.params.id ,  {encuTipoEstado:encuTipoEstado}).then(data => data);
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    }
    this.$store.commit('setCargaDatos', false);
  },
  updated() {

    if (document.getElementById('scripthistoria') && this.secciones.length > 0 && !this.loading2) {
      if (this.pagAnterior != this.pag) {
        document.getElementById('scripthistoria').innerHTML = '';
        this.pagAnterior = this.pag;
      }
      if (document.getElementById('scripthistoria').innerHTML.length == 0) {
        let s = '';
        for (let i = 0; i < this.secciones.length; i++) {
          const data = this.secciones[i].campos;
          if (this.secciones[i].ecseScript) {
            s = s + this.secciones[i].ecseScript;
          }
          if (data) {
            for (let j = 0; j < data.length; j++) {
              if (data[j].ecseScript) {
                s = s + data[j].ecseScript;
              }
            }
          }
        }
        this.script = `<script>${ s }<${"/"}script>`;
        postscribe('#scripthistoria', this.script);
      }
    }
  },

  methods: {
    ejecutarInput(id, valor) {
      let event = new Event('input');
      document.getElementById(id).value = valor;
      document.getElementById(id).dispatchEvent(event);
    },
    async cancelar() {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea salir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, salir!"
      }).then(result => result.value);
      if (result) {
        this.$router.push('/encuestas');
      }
    },
    async cargar() {
      if (!this.insertando && this.seccionesvista.length > 0) {
        this.insertando = true;
        this.loading2 = true;
        var formHistoria2 = [];
        this.seccionesvista.forEach(s => {
          s.campos.forEach(c => {
            if (this.formHistoria.find(e => e.ecseId == c.ecseId) != undefined) {
              formHistoria2.push(this.formHistoria.find(e => e.ecseId == c.ecseId))
            }
          });

        });
        this.$v.formHistoria2.$touch();

        if (!this.$v.formHistoria2.$error ) {
          try {
            if (this.isNew) {
              if (this.getPermiso("crearEncuestas")) {
                let data = await this.$apiService.create(this.url + "create", formHistoria2).then(data => data);
                if (data) {
                  this.isNew = false;
                  this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;
                }
              }

            } else {
              if (this.getPermiso("editarEncuestas")) {
                let data = await this.$apiService.update(this.url + "update/" + this.$route.params.id, formHistoria2).then(data => data);
                if (data) {
                  this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;
                }
              }

            }
          } catch (error) {
            this.loading = false;
            console.log(error);
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }
        } else {
          this.$swal.fire({
            title: "Campos vacios de la historia!",
            text: "por favor diligenciarlos",
            icon: "warning"
          });
        }

        this.loading2 = false;
        // }
        this.insertando = false;
      }
    },
    getValues(values) {
      const lista = [];

      var datos = values.split('|');
      for (let i = 0; i < datos.length; i++) {
        let d = datos[i];
        lista.push({
          texts: d.split(';')[0],
          values: d.split(';')[1]
        });
      }
      return lista;

    },
    getMaestraName(id, maestra) {
      if (id != null) {
        var ma = maestra.filter(e => e.comaNombrecorto == id);
        if (ma.length > 0) {
          var m = ma[0];
          return m.comaId;
        }
      }

    },
    async agregarEncuesta() {
      this.loading = true;
      var formHistoria2 = [];
      this.seccionesvista.forEach(s => {
        s.campos.forEach(c => {
          if (this.formHistoria.find(e => e.ecseId == c.ecseId) != undefined) {
            formHistoria2.push(this.formHistoria.find(e => e.ecseId == c.ecseId))
          }
        });

      });
      this.$v.formHistoria2.$touch();

      if (!this.$v.formHistoria2.$error ) {
        try {
          if (this.isNew) {
            if (this.getPermiso("crearEncuestas")) {
              let data = await this.$apiService.create(this.url + "create", formHistoria2).then(data => data);
              if (data) {
                let tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_ENCUESTA").then(data => data);
                let encuTipoEstado = tipoestado.find(e => e.comaNombrecorto == 'TERMINADA').comaId;
                await this.$apiService.update( "encu/encuestas/update/" + this.$route.params.id , {encuTipoEstado:encuTipoEstado}).then(data => data);
                this.isNew = false;
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
            }

          } else {

            if (this.getPermiso("editarEncuestas")) {
              let data = await this.$apiService.update(this.url + "update/" + this.$route.params.id, formHistoria2).then(data => data);
              if (data) {
                let tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_ENCUESTA").then(data => data);
                let encuTipoEstado = tipoestado.find(e => e.comaNombrecorto == 'TERMINADA').comaId;
                await this.$apiService.update( "encu/encuestas/update/" + this.$route.params.id ,  {encuTipoEstado:encuTipoEstado}).then(data => data);
                this.isNew = false;
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
            }

          }
          this.$router.push({
            name: 'Encuestaspanel',
            params: {
              id: this.factura.encuId
            }
          });
        } catch (error) {
          this.loading = false;
          console.log(error);
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      } else {
        this.$swal.fire({
          title: "Campos vacios de la historia!",
          text: "por favor diligenciarlos",
          icon: "warning"
        });
      }
      this.loading = false;
    },
    limpiar() {
      this.formHistoria = [];
    }
  }
};

</script>

<style scoped>
#scroll-historia {
  max-height: 700 px !important;
  overflow: auto;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below VERSION 2.1.8 */
  {
  opacity: 0;
  transform: translateX(20px);
}

</style>
