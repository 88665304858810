<template>
<v-card class="mb-12">
    <v-container>
        <v-alert text prominent dense type="error" v-if="mensaje">
            {{mensaje}}
        </v-alert>
        <template v-if="mensaje == null && secciones.length > 0">
            <v-row v-for="seccion in  secciones" :key="seccion.ecseId">
                <v-col cols="12" sm="12" md="12" class="primary--text">
                    <h3>{{seccion.ecseNombre}}</h3>
                </v-col>
                <v-col cols="12" sm="12" md="12" :key="seccion.ecseId" class="primary--text" v-if="seccion.ecseHistorico">
                    {{cifaFecha}}
                </v-col>
                <template v-for="c in  seccion.campos">
                    <v-col cols="12" sm="12" :md="c.campo.ecseCol" :key="'c'+c.enerId">
                        <div v-if="c.campo.tipodato.comaNombrecorto == 'LISTA' || c.campo.tipodato.comaNombrecorto == 'SI/NO' && seccion.ecseCalculado">
                            <span class="black--text">{{c.campo.ecseNombre}}</span>
                            <span class="grey--text caption pa-5">{{`${getValues(c.campo.ecseValores, c.enerValor)}(${c.enerValor})`}}</span>
                        </div>
                        <div v-else>
                            <span class="black--text">{{c.campo.ecseNombre}}</span>
                            <span class="grey--text caption pa-5">{{c.enerValor}}</span>
                        </div>
                    </v-col>
                </template>
            </v-row>
        </template>
    </v-container>
</v-card>
</template>

<script>
import generales from '../../mixins/generales.js';
export default {
    props: {
        datos: Object,
    },
     mixins: [generales],
    data() {
        return {
            menu: false,
            tab: "tab-1",
            factura: null,
            secciones: [],
            campos: [],
            Historia: [],
            historial: [],
            encuFecha: null,
            mensaje: null,
            tipocampos: [],
        };
    },
    created: async function () {
        if (this.getPermiso("verEncuestas")) {
            this.$store.commit('setCargaDatos', true);
            if (this.datos) {
                if (this.datos.secciones.length > 0) {
                    this.secciones = this.datos.secciones
                    this.encuFecha = this.datos.encuFecha
                    this.mensaje = null;
                } else {
                    this.mensaje = "La encuesta aun no ha sido tramitada";
                }
            } else {
                this.mensaje = "No se a registrado encuesta";
            }
            this.$store.commit('setCargaDatos', false);
        }

    },
    methods: {
        getValues(values, valor) {
            const lista = [];
            if (values) {
                var datos = values.split('|');
                for (let i = 0; i < datos.length; i++) {
                    let d = datos[i];
                    lista.push({
                        texts: d.split(';')[0],
                        values: d.split(';')[1]
                    });
                }
                return (lista.filter(e => e.values == valor).length > 0) ? lista.filter(e => e.values == valor)[0].texts : '';
            }
            return '';
        },
    }
};
</script>

<style>

</style>
