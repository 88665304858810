<template>
    <div>
        <v-container lighten-5>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <span class="mx-2 secondary--text display-2 float-left">Encuestas</span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                        <v-icon dark>filter_list</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="tabla">
                    <v-card elevation="0" class="rounded-xl">
                        <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="true">
                            <template v-slot:header>
                                <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                                <v-toolbar dark class="mb-1">
                                    <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true" v-if="getPermiso('crearEncuestas')">
                                        agregar
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:default="{ items, isExpanded, expand }">
                                <v-container class="pt-0">
                                    <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                        <v-col v-for="item in items" :key="item.comaId" cols="12" sm="12" md="10" lg="10">
                                            <v-card elevation="0" class="rounded-xl">
                                                <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                                    <v-row no-gutters>
                                                        <v-col cols="10" sm="10" md="10" lg="10">
                                                            <v-row no-gutters>
                                                                <template v-for="(p,i) in tabla.primarios">
                                                                    <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                        {{p.titulo}} <span :class="p.class">
                                                                            {{ p.valor(item) }}
                                                                        </span>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="2" sm="2" md="2" lg="2">
                                                            <v-menu bottom left>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                        <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list>
                                                                    <template v-for="(m, i) in tabla.menu">
                                                                        <template v-if="m.show(item)">
                                                                            <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                                <v-list-item-icon>
                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                </v-list-item-icon>
                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                            </v-list-item>
                                                                            <v-list-item v-else :key="i" @click="m.action(item)">
                                                                                <v-list-item-icon>
                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                </v-list-item-icon>
                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                            </v-list-item>
                                                                        </template>
                                                                    </template>
                                                                </v-list>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-title>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-row no-gutters>
                                                        <template v-for="(p,i) in tabla.segundarios">
                                                            <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                {{p.titulo}} <span :class="p.class">
                                                                    {{ p.valor(item) }}
                                                                </span>
                                                            </v-col>
                                                        </template>
                                                        <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                            <v-row>
                                                                <v-col cols="2" sm="2" md="1" lg="1">
                                                                    <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                        <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                        <v-icon v-else>mdi-chevron-up</v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                                <v-col cols="10" sm="10" md="11" lg="11">
                                                                    <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <template v-if="tabla.expandible">
                                                    <v-divider v-if="isExpanded(item)"></v-divider>
                                                    <v-card-text v-if="isExpanded(item)">
                                                        <v-container>
                                                            <template v-for="items in tabla.expandibleItems(item) ">
                                                                <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                                    <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`">
                                                                        <template v-for="(p,i) in tabla.expanItem">
                                                                            <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                                {{p.titulo}} <span :class="p.class">
                                                                                    {{ p.valor(items) }}
                                                                                </span>
                                                                            </v-col>
                                                                        </template>
                                                                        <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                            <v-menu bottom left>
                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                    <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                        <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                                    </v-btn>
                                                                                </template>
                                                                                <v-list>
                                                                                    <template v-for="(m, i) in tabla.expanMenu">
                                                                                        <template v-if="m.show(items)">
                                                                                            <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                                <v-list-item-icon>
                                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                                </v-list-item-icon>
                                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                            </v-list-item>
                                                                                            <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                                <v-list-item-icon>
                                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                                </v-list-item-icon>
                                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                            </v-list-item>
                                                                                        </template>
                                                                                    </template>
                                                                                </v-list>
                                                                            </v-menu>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-sheet>
                                                            </template>
                                                        </v-container>
                                                    </v-card-text>
                                                </template>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <template v-slot:no-data>
                                <v-row class="mt-2" align="center" justify="center">
                                    <v-col cols="10" sm="10" md="10" lg="10">
                                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                            No se encontro nada.
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:loading>
                                <v-row class="mt-2" align="center" justify="center">
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        <Loader />
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:footer>
                                <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                            </template>
                        </v-data-iterator>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="dialog" width="800px" max-width="800px">
            <v-card>
                <v-card-title class="text-h5 primary">{{formTitle}}</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined dense :error="$v.form.copeId.$error" v-model="form.copeId" :items="personas.personas" :loading="personas.isLoading" :search-input.sync="personas.search" @keypress.enter="searchPersonas()" no-filter hide-no-data hide-selected item-text="copeIdentificacion" item-value="copeId" item-color="primary" label="Paciente" clearable>
                                  <template v-slot:selection="data">
                                                {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.copeIdentificacion">

                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                            <v-autocomplete outlined dense :error="$v.form.cocoId.$error" v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato"  clearable>
                                            <template v-slot:selection="data">
                                                {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                        <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                      </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined dense :error="$v.form.coenId.$error" v-model="form.coenId" :items="tipoencuestas" item-text="coenNombre" item-value="coenId" item-color="primary" label="Encuesta" clearable>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="agregar()"> guardar</v-btn>
                    <v-btn color="primary" text @click="close()"> Cancelar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import generales from '../../mixins/generales.js';
import {
  required
} from "vuelidate/lib/validators";
export default {
  mixins: [generales],
  data() {
    return {
      cancreate: false,
      isLoading: false,
      searchP: null,
      url: "encu/encuestas/",
      Peliminar: false,
      form: {
        copeId: null,
        coenId: null,
        cocoId:null,
        encuTipoEstado: null,
        encuFecha: new Date().yyyymmdd(),
        createdBy: null
      },
      personas: {
        personas: [],
        search: null,
        error: "",
        isLoading: false
      },
      tabla: {
        itemKey: 'encuId',
        orderBy: 'encuFecha',
        expandible: false,
        expandibleKey: 'encuId',
        expandibleTitle: () => {
          return ``
        },
        expandibleItems: () => {
          return []
        },
        primarios: [{
            titulo: 'PERSONA',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '12',
              lg: '12'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.persona ? `${item.persona.tblConfPersonasnaturales[0].copnNombre1 + " "+item.persona.tblConfPersonasnaturales[0].copnNombre2 + " "+item.persona.tblConfPersonasnaturales[0].copnApellido1 + " "+item.persona.tblConfPersonasnaturales[0].copnApellido2}` : ''
            },
            show: (item) => {
              return item ? true : false
            }
          }, {
            titulo: 'FECHA',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '4',
              lg: '4'
            },
            class: 'green--text',
            valor: (item) => {
              return item.encuFecha
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'IDENT.',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '4',
              lg: '4'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.persona ? item.persona.copeIdentificacion : ''
            },
            show: (item) => {
              return item ? true : false
            }
          },
          {
            titulo: 'ESTADO',
            tamaño: {
              cols: '12',
              sm: '12',
              md: '4',
              lg: '4'
            },
            class: 'primary--text',
            valor: (item) => {
              return item.estado ? item.estado.comaNombrecorto : ''
            },
            show: (item) => {
              return item ? true : false
            }
          },
        ],
        segundarios: [{
          titulo: 'ENCUESTA',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '6',
            lg: '6'
          },
          class: 'primary--text',
          valor: (item) => {
            return item.encuesta ? item.encuesta.coenNombre : ''
          },
          show: (item) => {
            return item ? true : false
          }
        },{
          titulo: 'EPS',
          tamaño: {
            cols: '12',
            sm: '12',
            md: '6',
            lg: '6'
          },
          class: 'primary--text',
          valor: (item) => {
            return item.contrato ? (item.contrato.tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR': item.contrato.tblConfPersonasempresas[0].coemNombre:''
          },
          show: (item) => {
            return item ? true : false
          }
        } ],
        expanItem: [],
        expanMenu: [],
        menu: [{
            title: 'Ver',
            icon: 'mdi-desktop-mac-dashboard',
            color: 'success',
            tipeAction: 'link',
            action: (item) => {
              return {
                name: 'Encuestaspanel',
                params: {
                  id: item.encuId
                }
              }
            },
            show: (item) => {
              return item.estado ? (item.estado.comaNombrecorto != 'INICIADA') : false
            }
          },
          {
            title: 'Tramitar',
            icon: 'mdi-ballot-outline',
            color: 'primary',
            tipeAction: 'link',
            action: (item) => {
              return `/crearencuesta/${item.encuId}/${item.coenId}`
            },
            show: (item) => {
              return item.estado ? (item.estado.comaNombrecorto != 'TERMINADA' && this.cancreate) : false
            }
          },
          {
            title: 'Vaciar',
            icon: 'delete',
            color: 'error',
            tipeAction: 'funcion',
            action: (item) => {
              this.vacear(item.encuId)
            },
            show: (item) => {
              return item.estado ? (item.estado.comaNombrecorto != 'INICIADA' && this.Peliminar) : false
            }
          },
          {
            title: 'Eliminar',
            icon: 'delete',
            color: 'error',
            tipeAction: 'funcion',
            action: (item) => {
              this.eliminar(item.encuId)
            },
            show: (item) => {
              return item ? this.Peliminar : false
            }
          },
        ]
      },
      filtros: [{
          campo: "encuFecha",
          text: "Fecha",
          value: null,
          tipo: "fecha",
          menu: false,
          searchNormal: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },

        {
          campo: "encuTipoEstado",
          text: "Estado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          searchNormal: true,
          isLoading: false,
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        }, 
        {
          campo:"copeId",
          text: "Paciente",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "copeId",
          buscar: 'paciente',
          isLoading: false,
          search: null,
          //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        },
        {
          campo: "coenId",
          text: "Encuesta",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "coenNombre",
          listavalue: "coenId",
        },
      ],
      tipoencuestas: [],
      tipoestado: [],
      images: [],
      imagesE14: [],
      dialogE14: false,
      contratos:[]
    };
  },
  validations: {
    form: {
      copeId: {
        required
      },
      coenId: {
        required
      },
      cocoId:{
        required
      },
    }
  },
  created: async function() {
    if (this.getPermiso("verEncuestas")) {
      this.filtros[1].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_ENCUESTA").then(data => data);
      this.filtros[3].lista = this.tipoencuestas = await this.$apiService.index("sistema/encuestas/index").then(data => data);
      this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
      this.contratos = this.contratos.filter(e =>  e.estado.comaNombrecorto == 'ACTIVO' &&  new Date(e.cocoFechainicio.replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.cocoFechafinal.replace(/-+/g, '/')).getTime() >= new Date().getTime());
     this.form.encuTipoEstado = this.tipoestado.find(e => e.comaNombrecorto == 'INICIADA').comaId;
      this.form.createdBy = this.user.id;
      await this.search(this.filtros);
    } else {
      this.$router.push("/");
    }
    if (this.getPermiso("eliminarEncuestas")) {
      this.Peliminar = true;
    }
    if (this.getPermiso("crearEncuestas")) {
      this.cancreate = true;
    }
    if (this.getPermiso("editarEncuestas")) {
      this.cancreate = true;
    }

  },
  methods: {
    async searchPersonas() {
      this.personas.isLoading = true;
      if (this.personas.search.length > 0 && this.personas.search.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.personas.search.toUpperCase()).then(data => {
          this.personas.personas = data;
        });
      }
      this.personas.isLoading = false;
    },
    async search(filtros) {
      this.lista = this.listaCopia = [];
      this.loadingTable = true;
      // if (this.user.role.indexOf('ADMIN') < 0) {
      //   filtros.find(e => e.campo == 'copeId').value = this.user.persona;
      // }
      const data = await this.$apiService.index(`encu/encuestas/buscar/?${this.filtrar(filtros,this.page)}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.loadingTable = false;
    },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              await this.search(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.close();
            }

          } else {
            var id = this.form.encuId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              await this.search(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.close();
            }
          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }

      }
    },
    async vacear(id) {
      try {
        if (this.getPermiso("eliminarEncuestas")) {
          var result = await this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
          if (result) {
            let data = await this.$apiService.delete("sistema/encuestarespuestas/delete/" + id + "/").then(data => data);
            if (data) {
              this.$swal.fire({
                title: "Eliminado!",
                text: "ha sido eliminado con exito.",
                icon: "success"
              });
            }

          }
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async eliminar(id) {
      try {
        if (this.getPermiso("eliminarEncuestas")) {
          var result = await this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
          if (result) {
            let data = await this.$apiService.delete("encu/encuestas/delete/" + id + "/").then(data => data);
            if (data) {
              await this.search(this.filtros);
              this.$swal.fire({
                title: "Eliminado!",
                text: "ha sido eliminado con exito.",
                icon: "success"
              });
            }

          }
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    close() {
      this.dialog = false;
      this.form = {
        copeId: null,
        coenId: null,
        encuTipoEstado: null,
        encuFecha: new Date().yyyymmdd(),
        createdBy: null
      }
    }
  }
};

</script>

<style lang="scss" scoped>
.uploader {
  width: 100%;
  background: rgb(114, 9, 9);
  color: #fff;
  padding: 40px 15px;
  text-align: center;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  position: relative;

  .fa-cloud-upload {
    font-size: 85px;
  }

  .file-input {
    width: 200px;
    margin: auto;
    height: 68px;
    position: relative;

    label,
    input {
      background: #fff;
      color: rgb(58, 9, 114);
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 10px;
      border-radius: 4px;
      margin-top: 7px;
      cursor: pointer;
    }

    input {
      opacity: 0;
      z-index: -2;
    }
  }

  .images-preview {
    display: flex;
    flex-wrap: wrap;

    .img-wrapper {
      width: 160px;
      display: flex;
      flex-direction: column;
      margin: 10px;
      height: 150px;
      justify-content: space-between;
      background: #fff;

      //box-shadow: 5px 5px 20px #3e3737;
      img {
        height: 150px;
      }
    }
  }
}

</style>
